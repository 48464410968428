module.exports = {
  promo: {
    title: "What can TeqEngine do for your business?",
    description:
      "In today's digital-first landscape, agility and innovation are not just buzzwords — they're imperatives. Reach out for a free consultation to discover how TeqEngine can be your strategic partner in achieving digital excellence.",
    items: [
      {
        title: "Software Engineering Solutions",
        description:
          "Your technology stack, simplified. We offer a unified approach to software engineering, combining custom software solutions, full-stack development, and cloud & DevOps services. Our team of experts ensures that your business is equipped with scalable, secure, and efficient systems that drive growth and innovation.",
        image: "img/software-engineering.jpeg",
      },
      {
        title: "Fractional CTO Services",
        description:
          "Strategic technology leadership without the full-time commitment. Our Fractional CTO services offer you the expertise and guidance needed to align your technology strategy with business objectives. Whether you're a startup or an established enterprise, we provide actionable insights that make a difference.",
        image: "img/daniel-cto.jpg",
      },
      {
        title: "Engineering Staffing Solutions",
        description:
          "The right talent for your tech needs. Our specialized staffing services provide you with skilled professionals in full-stack development, cloud solutions, and DevOps. Eliminate the bottlenecks in your projects with our vetted, high-caliber engineering talent.",
        image: "img/staffing-solutions.jpeg",
      },
    ],
  },
  promo2: {
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "Increase sales by showing true dynamics of your website. Build your online store’s the trust using Social Proof & Urgency for your business.",
        image: "img/promo-1.png",
        image8: "img/service6.jpg",
      },
      {
        title: "Increase store trust",
        description:
          "Build your online store’s trust using the Social Proof, Security & Urgency. Realize the importance of social proof in customer’s purchase decision.",
        image: "img/promo-2.png",
        image8: "img/service2.jpg",
      },
      {
        title: "Growth guaranteed",
        description:
          "Realize importance of social proof in customer’s purchase decision. Increase sales by showing true dynamics, talent of your website.",
        image: "img/promo-3.png",
        image8: "img/service7.jpg",
      },
    ],
  },
  promo3: {
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "Increase sales by showing true dynamics of your website. Build your online store’s the trust using Social Proof & Urgency for your business.",
        icon: "ti-bar-chart icon-color-1 icon rounded",
      },
      {
        title: "Increase store trust",
        description:
          "Build your online store’s trust using the Social Proof, Security & Urgency. Realize the importance of social proof in customer’s purchase decision.",
        icon: "ti-shield icon-color-2 icon rounded",
      },
      {
        title: "Growth guaranteed",
        description:
          "Realize importance of social proof in customer’s purchase decision. Increase sales by showing true dynamics, talent of your website.",
        icon: "ti-cup icon-color-3 icon rounded",
      },
    ],
  },
  promo6: {
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "Proactively provide access to distinctive quality vectors for adaptive solutions.",
        image: "ti-bar-chart icon-md icon color-primary",
      },
      {
        title: "Increase store trust",
        description:
          "Interactively reintermediate reliable platforms rather than synergistic e-commerce.",
        image: "ti-headphone-alt icon-md icon color-primary",
      },
      {
        title: "Growth guaranteed",
        description:
          "Professionally pursue standards compliant networks whereas impactful experiences.",
        image: "ti-vector icon-md icon color-primary",
      },
    ],
  },
  promo7: {
    items: [
      {
        title: "Keyword Rankings",
        description: "Increase sales by showing true dynamics of your website.",
        image: "ti-bar-chart text-white",
      },
      {
        title: "Backlink Analytics",
        description:
          "Build your online store’s trust using Social Proof & Urgency.",
        image: "ti-vector text-white",
      },
      {
        title: "Competitor Analysis",
        description:
          "Realize importance of social proof in customer’s purchase decision.",
        image: "ti-stats-up text-white",
      },
    ],
  },
  aboutUs: {
    title: "Who we are",
    description:
      "Proactively syndicate open-source e-markets after low-risk high-yield synergy. Professionally simplify visionary technology before team driven sources.",
    items: [
      {
        title: "Increase conversion",
        description: "Display recent conversions, build credibility and trust.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2",
      },
      {
        title: "Product analytics",
        description: "A top promo bar that counts down until a few discounts.",
        icon: "ti-stats-up rounded mr-3 icon icon-color-3",
      },
    ],
    counts: [
      {
        title: "% Client Satisfaction",
        count: 100,
        icon: "ti-cup",
        boxClass: "single-card box-animation-3 icon-color-2",
        boxClass5: "single-card box-animation-3 counter-card icon-color-2",
      },
      {
        title: "Hour Daily Website Uptime & Security Monitoring",
        count: 24,
        icon: "ti-user color-secondary",
        boxClass: "single-card box-animation-4 primary-bg text-white",
        boxClass5:
          "single-card box-animation-4 counter-card primary-bg text-white",
      },
      {
        title: "Monthly Unique Visitors",
        count: 6500,
        icon: "ti-medall-alt color-primary",
        boxClass: "single-card box-animation-1 secondary-bg text-white",
        boxClass5:
          "single-card box-animation-1 counter-card secondary-bg text-white",
      },
      {
        title: "Security Breaches",
        count: 0,
        icon: "ti-headphone-alt",
        boxClass: "single-card box-animation-2 icon-color-1",
        boxClass5: "single-card box-animation-2 counter-card icon-color-1",
      },
    ],
  },
  aboutUs2: {
    title: "TeqEngine: Your Partner in Digital Transformation",
    description:
      "In a landscape where technology is the linchpin of business success, many companies find themselves struggling with software challenges and strategic gaps. At TeqEngine, we understand that businesses seek more than just technical fixes — they aim for solutions that drive tangible business advantages.",
    image: "img/hero-bg-5.jpg",
    items: [
      {
        title: "Comprehensive Solutions for Your Business",
        description:
          "We specialize in Full-Stack Software Solutions, Fractional CTO Solutions, and Engineering Staffing Solutions. Our expertise is designed to address your most pressing technical and strategic needs, enabling you to focus on what you do best—running your business.",
        longDescription: "",
        icon: "ti-stats-up rounded mr-3 icon icon-color-3",
      },
      {
        title: "Results-Driven Approach",
        description:
          "Our solutions are not just about overcoming technical hurdles; they're about transforming these challenges into business success. We are committed to delivering results that matter, from increased operational efficiency to accelerated growth.",
        longDescription: "",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2",
      },
    ],
  },
  aboutUs6: {
    aboutImage: "img/business.png",
    items: [
      {
        title: "Discussion for Idea",
        description:
          "Enthusiastically coordinate competitive users vis-a-vis results.",
        longDescription: "",
        icon: "ti-light-bulb rounded mr-3 icon icon-color-3",
      },
      {
        title: "Perfect testing",
        description:
          "Credibly evisculate covalent quality vectors and maintainable.",
        longDescription: "",
        icon: "ti-paint-bucket rounded mr-3 icon icon-color-4",
      },
    ],
  },
  service: {
    title: "Happy to Assist You",
    description:
      "Professionally simplify visionary technology before team driven sources.",
    items: [
      {
        title: "Search engine optimization",
        description:
          "Synergistically deliver next-generation relationships whereas bleeding-edge resources.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-1",
      },
      {
        title: "Social media marketing",
        description:
          "Phosfluorescently empower compelling intellectual capital and revolutionary web services. Compellingly develop cross-media.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-2",
      },
    ],
  },
  service2: {
    title: "How TeqEngine powers businesses forward",
    description:
      "Our experienced web professionals bring results for your business. Leave the tech stuff to us so you can focus on running your business!",
    items: [
      {
        title: "Web Design & Development",
        description:
          "We build modern, fully functional websites that are fast, secure, and mobile-reponsive. ",
        icon: "ti-vector icon-color-4 icon rounded",
        icon6: "ti-vector icon-md color-secondary",
        image: "img/service7.jpg",
      },
      {
        title: "Search Engine Optimization",
        description:
          "All of our websites are SEO-focused and made to perform well on search engines. We'll make Google love your site.",
        icon: "ti-bar-chart icon-color-1 icon rounded",
        icon6: "ti-bar-chart icon-md color-secondary",
        image: "img/service1.jpg",
      },
      {
        title: "Cloud Hosting",
        description:
          "We use high-performing and secure servers to make sure your website is fast, always available, and secure for your customers.",
        icon: "ti-email icon-color-3 icon rounded",
        icon6: "ti-email icon-md color-secondary",
        image: "img/service2.jpg",
      },
      {
        title: "Cybersecurity",
        description:
          "Our 24/7 threat monitoring systems will make sure that you are protected from all digital threats that websites constantly face.",
        icon: "ti-announcement icon-color-6 icon rounded",
        icon6: "ti-announcement icon-md color-secondary",
        image: "img/service6.jpg",
      },
      {
        title: "Digital Advertising",
        description:
          "Our marketing experts have years of experience running ad campaigns on platforms such as Google and Facebook.",
        icon: "ti-layout-media-left-alt icon-color-2 icon rounded",
        icon6: "ti-layout-media-left-alt icon-md color-secondary",
        image: "img/service3.jpg",
      },
      {
        title: "Email Marketing",
        description:
          "Our experts create well-crafted email campaigns that connect with your audience, promote your brand, and increase sales.",
        icon: "ti-mobile icon-color-5 icon rounded",
        icon6: "ti-mobile icon-md color-secondary",
        image: "img/service5.jpg",
      },
    ],
  },
  service4: {
    title: "We Are Happy to Assist You all Time MomentInsights",
    description:
      "Energistically enhance user-centric e-markets before client-focused initiatives. Enthusiastically reconceptualize proactive intellectual capital whereas top-line imperatives.",
    items: [
      {
        title: "Search engine optimization",
        description:
          "Where we use search engines to market your product; through paid methods (Pay per Click (PPC) Services)",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2",
      },
      {
        title: "Social media marketing",
        description:
          "While browsing Facebook or Twitter or even Linkedin, you are subjected to advertisements.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-3",
      },
    ],
  },
  service5: {
    title: "Accelerate Your Business with Specialized Tech Solutions",
    description:
      "Achieve more with a digital platform that aligns with your business goals. Our full-stack software solutions are custom-tailored, scalable, and built with security in mind.",
    items: [
      {
        title: "Full-Stack Software Solutions",
        description:
          "Achieve more with a digital platform that aligns with your business goals. Our full-stack software solutions are custom-tailored, scalable, and built with security in mind.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-3",
      },
      {
        title: "Fractional CTO Solutions",
        description:
          "Make technology your business advantage. Our Fractional CTO services provide the strategic leadership you need to make technology a key enabler for your business growth.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2",
      },
    ],
    items2: [
      {
        title: "Engineering Staffing Solutions",
        description:
          "Accelerate your projects with specialized talent. Our staffing solutions connect you with professionals skilled in full-stack development, cloud computing, and DevOps.",
        icon: "ti-email rounded mr-3 icon icon-color-3",
      },
      {
        title: "Cloud & DevOps",
        description:
          "Maintain operational excellence around the clock. Our Cloud & DevOps solutions are designed to ensure reliability, security, and optimal performance.",
        icon: "ti-vector rounded mr-3 icon icon-color-4",
      },
    ],
  },
  service6: {
    title: "Market your business to own your market",
    description:
      "We provide professional services for grow your business and increases sell digital product",
  },
  serviceAboutUs: {
    title: "TeqEngine powers businesses forward",
    description:
      "Our mission is to provide the best digital and web services possible so businesses and professionals can get more leads, conversions, and results online. This allows you to do what you best - focusing on running your business - by leaving the tech stuff to us!",
    items: [
      {
        title: "Our Mission",
        description:
          "Where we use search engines to market your product; through paid methods (Pay per Click (PPC) Services)",
        image: "img/mission.jpg",
      },
      {
        title: "Our Vision",
        description:
          "While browsing Facebook or Twitter or even Linkedin, you are subjected to advertisements",
        image: "img/vision.jpg",
      },
      {
        title: "Why Us?",
        description:
          "Email marketing is one of the most popular strategies offered by full-service digital marketing agencies",
        image: "img/grow.jpg",
      },
    ],
  },
  price: {
    title: "Pricing Packages",
    description: `Our packages give your business everything you'll ever need for an amazing online presence. Pricing starts with an upfront website creation fee and then a monthly maintanence option. Cancel at any time. No hidden fees. Satisfaction guaranteed.`,
    packages: [
      {
        price: "Web Basic",
        license: "$",
        isFeatured: false,
        features: [
          "High-performance website hosting",
          "Edit your website instantly online",
          "Mobile-first design",
          "Domain registration and maintenance",
          "Security with a complementary SSL certificate",
          "Online forms capability",
          "Basic SEO",
          "Cancel at any time",
          "Satisfaction guaranteed",
        ],
      },
      {
        price: "Web Launch",
        license: "$$",
        isFeatured: true,
        features: [
          "Everything in Web Basic package",
          "E-Commerce integration (if needed)",
          "SEO keyword research",
          "On-page SEO",
          "SEO Link Building",
          "Search Engine & SEO Analytics",
          "VIP customer support",
          "24/7 cybersecurity monitoring",
          "Monitor all traffic with analytics",
          "Cancel at any time",
          "Satisfaction guaranteed",
        ],
      },
      {
        price: "Web Accelerator",
        license: "$$$",
        isFeatured: false,
        features: [
          "Everything in Web Basic package",
          "Everything in Web Launch package",
          "Email marketing campaigns",
          "Google adwords",
          "Facebook Ads",
          "Instagram Ads",
          "Social Media strategy",
          "Advanced cybersecurity",
          "Cancel at any time",
          "Satisfaction guaranteed",
        ],
      },
    ],
  },
  process: {
    title: "Vision-to-Value Process",
    description: `Embark on a seamless journey from initial concept to successful implementation with our structured engagement process. We ensure clarity, efficiency, and excellence every step of the way. Let's transform your vision into a tangible business solution.`,
    steps: [
      {
        stepName: "Intro / Discovery Call",
        timeFrame: "Initial 30-minute video call",
        image: "img/video-call.jpeg",
        description:
          "We kick off with a personal consultation to understand the challenges you're facing and to identify how we can add value to your project.",
        deliverables: [
          "Understanding of client needs",
          "Initial problem identification",
          "Outline of potential solutions",
        ],
      },
      {
        stepName: "From Vision to Strategy",
        timeFrame: "2-week Discovery Phase",
        image: "img/ux-designs.png",
        description:
          "Transitioning from concepts to concrete plans, we engage in a comprehensive discovery phase, focusing on requirements, user experience, and architectural solutions.",
        deliverables: [
          "Requirements documentation",
          "UX/UI alignment",
          "Architectural blueprint",
        ],
      },
      {
        stepName: "Implementation",
        timeFrame: "6-weeks to Quick Win",
        image: "img/software-development.jpeg",
        description:
          "With a strategic roadmap in place, our team moves into the implementation phase, with a focus on delivering a quick win to drive momentum and value.",
        deliverables: [
          "Development and execution of solution",
          "Regular progress updates",
          "Quality assurance and documentation",
        ],
      },
    ],
  },
  caseStudies: {
    title: "Our Case Studies: A Track Record of Success",
    description:
      "At TeqEngine, we don't just deliver solutions; we create success stories. Our commitment to achieving tangible results for our clients is evident in our diverse project portfolio. Explore our case studies to see the real-world impact of our expertise:",
    items: [
      {
        name: "Founder & CMO",
        title: "Noveta Health",
        description:
          "TeqEngine did an exceptional job with the design and creation of our website. Gave us clear time horizons, communicated frequently, and offered a reasonable price.",
        url: "",
        image: "img/testimonial-icons/noveta-icon.png",
      },
      {
        name: "Founder",
        title: "Jupiter Digital Marketing",
        description:
          "TeqEngine is extremely responsive, results-oriented, and is amazing at web design & development. They actually deliver tangible results to our business and our revenue growth unlike most agencies out there.",
        url: "",
        image: "img/testimonial-icons/jds-icon.png",
      },
      {
        name: "Chief Marketing Officer",
        title: "Occum Health",
        description:
          "TeqEngine built us an exceptional website in a timely manner. He was responsive, attentive, and delivered a ton of value for a fair price.",
        url: "",
        image: "img/testimonial-icons/occum-icon.svg",
      },
      {
        name: "Founder, Lead Coach",
        title: "Alziari Coaching",
        description:
          "TeqEngine was fantastic, I'm very satisfied with the responsiveness, timeliness, and professionalism. He built our website in a much shorter span of time than I expected. I plan on continuing to work together!",
        url: "",
        image: "img/testimonial-icons/gab-icon.png",
      },
    ],
  },
  contact: {
    title: "Start a Conversation",
    description:
      "Message us and we will respond within 24 hours. Learn how TeqEngine can be the catalyst in elevating your software infrastructure and business trajectory. We offer customized quotes and a no-cost initial consultation.",

    office: "Main Office",
    address: "888 Prospect Street, Suite 200, La Jolla, CA 92037",
    phone: "858-503-3290",
    email: "daniel@teqengine.com",
  },
  hero: {
    title: "Fast, Stunning Web Design. Guaranteed.",
    description:
      "Our experienced design and development team will help transform your digital presence throu.",
    bgImage: "img/tech-bg.jpg",
    animations: [
      {
        className: "img-fluid d-block animation-one",
        src: "img/hero-animation-04.svg",
        width: "",
      },
      {
        className: "img-fluid d-none d-lg-block animation-two",
        src: "img/hero-animation-01.svg",
        width: "120",
      },
      {
        className: "img-fluid d-none d-lg-block animation-three",
        src: "img/hero-animation-02.svg",
        width: "120",
      },
      {
        className: "img-fluid d-none d-lg-block animation-four",
        src: "img/hero-animation-03.svg",
        width: "230",
      },
    ],
  },
  hero2: {
    title: "We are Digital Agency & Marketing",
    description:
      "Quickly morph client-centric results through performance based applications. Proactively facilitate professional human capital for cutting-edge",
    bgImage: "img/tech-bg.jpg",
    counters: [
      {
        name: "Happy Client",
        count: 2350,
      },
      {
        name: "App Download",
        count: 2150,
      },
      {
        name: "Total Rates",
        count: 2350,
      },
      {
        name: "Awards win",
        count: 2250,
      },
    ],
  },
  hero3: {
    bgImage: "img/curve-bg.jpg",
    images: {
      img1: "img/hero-animation-01.svg",
      img2: "img/hero-animation-02.svg",
      img3: "img/hero-animation-03.svg",
      img4: "img/hero-animation-04.svg",
    },
  },
  hero4: {
    title: "Build anything you want",
    description:
      "We offer Digital Marketing / SEO in Kalawad Road Rajkot, Gujarat. Find here details about our company",
    bgImage: "img/tech-bg.jpg",
  },
  hero5: {
    title: "Transforming Software Challenges into Business Success.",
    description:
      "Expert Consultancy in Full-Stack Software Development, Fractional CTO Services, and Engineering Staffing Solutions. Encountering technical roadblocks or lacking strategic direction? TeqEngine is your definitive solution for transforming software challenges into business advantages.",
    bgImage: "img/tech-bg.jpg",
  },
  hero7: {
    title: "We are Digital Agency & Marketing",
    description:
      "Professionally simplify integrated partnerships via enterprise-wide leadership skills. Appropriately streamline cross functional infomediaries without resource maximizing internal",
    bgImage: "img/tech-bg.jpg",
  },
  heroAboutUs: {
    title: "About Us",
    bgImage: "img/tech-bg.jpg",
  },
  trustedCompany: [
    {
      src: "img/aws-logo.png",
      style: { width: "110px" },
      alt: "amazon web services (AWS) cloud",
    },
    {
      src: "img/azure.png",
      style: { width: "120px" },
      alt: "azure cloud",
    },
    {
      src: "img/google-cloud-platform.png",
      style: { width: "140px" },
      alt: "Google Cloud Platform (GCP)",
    },
    {
      src: "img/node-logo.png",
      style: { width: "115px" },
      alt: "node.js",
    },
    {
      src: "img/react-logo.png",
      style: { width: "80px" },
      alt: "react.js",
    },
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png",
  ],
  trustedCompanyAboutUs: {
    title: "Trusted by companies",
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    companies: [
      "img/client-5-color.png",
      "img/client-1-color.png",
      "img/client-6-color.png",
      "img/client-3-color.png",
      "img/client-4-color.png",
      "img/client-5-color.png",
      "img/client-7-color.png",
      "img/client-2-color.png",
      "img/client-1-color.png",
      "img/client-3-color.png",
    ],
  },
  teamMember: {
    members: ["img/team-1.jpg", "img/team-2.jpg", "img/team-3.jpg"],
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA",
  },
};
