import React, { Fragment } from "react";
import { connect } from "react-redux";
import _data from "../../data";
import { Modal } from "@material-ui/core";
import { requestRecaptcha } from "../../actions";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";

class HeroSection5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      trustedCompany: [],
      video: "",
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      isAgreed: false,
      disableBtn: false,
      btnText: "Send",
      showSuccessModal: false,
      showErrorModal: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.API_URL = "https://api.teqengine.com/verifyRecaptcha";
  }

  componentDidMount() {
    this.setState({
      hero: _data.hero5,
      trustedCompany: _data.trustedCompanyGray,
      video: _data.video.link,
    });
  }

  async isRecaptchaVerified(token) {
    // get result from lambda function
    // const { data: isVerifiedResult } = await axios.post('http://localhost:4000/prod/verifyRecaptcha', { token })
    const { data: isVerifiedResult } = await axios.post(this.API_URL, {
      token,
    });
    this.setState({
      isVerified: isVerifiedResult,
    });
    this.props.dispatch(requestRecaptcha(isVerifiedResult));
  }

  changeBtnText(btnText) {
    this.setState({ btnText });
  }

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // send email
    this.sendEmail();

    // disable the button
    this.setState({
      disableBtn: true,
    });
  }

  async sendEmail() {
    const params = {
      email: this.state.email,
      name: this.state.name,
      phone: this.state.phone,
      company: this.state.company,
      message: this.state.message,
      formType: "Home Hero Quote Form",
    };
    // // TODO: fix recaptcha below
    // let isVerified = false;
    // try {
    //   isVerified = this.props.recaptcha.isVerified;
    // } catch (e) {
    //   isVerified = false;
    // }
    let isVerified = true;
    if (isVerified) {
      // recaptcha verified, send email
      const { data: emailRes } = await axios.post(
        "https://api.teqengine.com/sendEmail",
        params
      );
      if (emailRes && emailRes.sent === true) {
        // email was sent successfully, open success modal
        this.clearForm();
        this.setState({
          showSuccessModal: true,
          showErrorModal: false,
        });
      } else {
        // email failed, open error modal
        this.setState({
          showSuccessModal: false,
          showErrorModal: true,
        });
      }
    } else {
      // recaptcha not verified, open error modal
      this.setState({
        disableBtn: false,
        showErrorModal: true,
        showSuccessModal: false,
      });
    }
  }

  clearForm() {
    this.setState({
      disableBtn: false,
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
    });
  }

  closeModal() {
    this.setState({
      showSuccessModal: false,
      showErrorModal: false,
    });
  }

  renderModalBody() {
    if (this.state.showSuccessModal) {
      // render success message
      return (
        <div className="contact-modal-content">
          <img
            className="contact-modal-close"
            src="img/close.svg"
            onClick={this.closeModal.bind(this)}
            alt="close modal"
          />
          <h4 className="contact-modal-title">Contact Status</h4>
          <div className="contact-modal-message">
            <img
              className="contact-modal-success-icon"
              src="img/success-icon.svg"
              alt="success check mark"
            />
            <div>Your email was sent successfully!</div>
            <div>We will get back to you ASAP.</div>
          </div>
        </div>
      );
    } else if (this.state.showErrorModal) {
      // render error message
      if (this.props.recaptcha && this.props.recaptcha.isVerified === false) {
        // if recaptcha failed then notify them to email straight to daniel@teqengine.com
        return (
          <div className="contact-modal-content">
            <img
              className="contact-modal-close"
              src="img/close.svg"
              onClick={this.closeModal.bind(this)}
              alt="close modal"
            />
            <h4 className="contact-modal-title">Contact Status</h4>
            <div className="contact-modal-message">
              <img
                className="contact-modal-success-icon"
                src="/img/failed-icon.svg"
                alt="failure alert icon"
              />
              <div>Email failed. Bot detected.</div>
              <div>
                If you are a human please email{" "}
                <a href="mailto:daniel@teqengine.com">daniel@teqengine.com</a>{" "}
                directly.
              </div>
              <div>Thank you for your patience!</div>
            </div>
          </div>
        );
      } else {
        // recaptcha was successful, must be nodemailer API error
        return (
          <div className="contact-modal-content">
            <img
              className="contact-modal-close"
              src="img/close.svg"
              onClick={this.closeModal.bind(this)}
              alt="close modal"
            />
            <h4 className="contact-modal-title">Contact Status</h4>
            <div className="contact-modal-message">
              <img
                className="contact-modal-success-icon"
                src="/img/failed-icon.svg"
                alt="failure alert icon"
              />
              <div>Your email failed to send.</div>
              <div>
                Please try again or email{" "}
                <a href="mailto:daniel@teqengine.com">daniel@teqengine.com</a>{" "}
                directly.
              </div>
              <div>Thank you!</div>
            </div>
          </div>
        );
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <GoogleReCaptcha
          onVerify={this.isRecaptchaVerified.bind(this)}
        /> */}
        <section
          className="hero-section background-img pt-100"
          style={{
            background: `url(${this.state.hero.bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "center / cover",
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="hero-content-left position-relative mt-lg-0 mt-md-0 mt-5 text-white home-hero-text-wrap">
                  <h1 className="text-white">
                    {/* {this.state.hero.title} */}
                    Transforming Software Challenges into Business Success
                  </h1>
                  <p className="lead">
                    {/* {this.state.hero.description} */}
                    Expert Consultancy in <b>Full-Stack Software Development</b>
                    , <b>Fractional CTO Services</b>, and{" "}
                    <b>Engineering Staffing Solutions</b>. Encountering
                    technical roadblocks or lacking strategic direction?
                    TeqEngine is your definitive solution for transforming
                    software challenges into business advantages.
                  </p>
                  <div className="video-promo-content mt-4 d-flex align-items-center">
                    {/* <a
                      href={this.state.video}
                      className="popup-youtube video-play-icon"
                    >
                      {" "}
                    </a>
                    <span className="ml-4">Watch video overview</span> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-lg-5">
                <div className="sign-up-form-wrap position-relative rounded p-5 gray-light-bg mt-5">
                  <div className="sign-up-form-header text-center mb-4">
                    <h4 className="mb-0">Request a quote</h4>
                    <p>Get a response within 24 hours</p>
                  </div>
                  <form
                    method="post"
                    className="sign-up-form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="form-group input-group">
                      <input
                        value={this.state.name}
                        onChange={(e) => this.handleFormValueChange("name", e)}
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <input
                        value={this.state.email}
                        onChange={(e) => this.handleFormValueChange("email", e)}
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <input
                        value={this.state.phone}
                        onChange={(e) => this.handleFormValueChange("phone", e)}
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Phone"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <textarea
                        onChange={(e) =>
                          this.handleFormValueChange("message", e)
                        }
                        value={this.state.message}
                        name="message"
                        id="msg"
                        className="form-control"
                        placeholder="Write your message"
                        cols="30"
                        rows="4"
                        style={{ resize: "none" }}
                        required
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <input
                        // onClick={this.handleSubmit}
                        disabled={this.state.disableBtn}
                        type="submit"
                        name="submit"
                        id="submit"
                        className="btn solid-btn btn-block"
                        value={this.state.btnText}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="section-shape position-absolute">
            <img src="img/wave-shap.svg" alt="shape" />
          </div>
        </section>
        <Modal
          className="contact-modal"
          open={this.state.showSuccessModal || this.state.showErrorModal}
          onClose={this.closeModal.bind(this)}
        >
          <div className="contact-modal-body">{this.renderModalBody()}</div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { recaptcha } = state;
  return { recaptcha };
};

export default connect(mapStateToProps)(HeroSection5);
