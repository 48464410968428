import React, { Component } from "react";
import { Container } from "@material-ui/core";

export default class PortfolioLead extends Component {
  render() {
    return (
      <Container maxWidth={"lg"}>
        <section
          className="our-services-section"
          style={{ padding: "100px 0 80px" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-7">
                <div className="section-heading text-center text-center">
                  <h2>TeqEngine powers businesses forward</h2>
                  <p className="lead" style={{ marginBottom: "0" }}>
                    Our mission is to provide the best digital and web services
                    possible so businesses and professionals can get more leads,
                    conversions, and results online. This allows you to do what
                    you best - focusing on running your business - by leaving
                    the tech stuff to us!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    );
  }
}
