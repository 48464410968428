import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/heroSectionAboutUs";
import ServiceSection from "../components/Service/ServiceAboutUs";
import MissionSection from "../components/Business";
import BusinessSection from "../components/AboutUs";
import AboutSection from "../components/AboutUs/AboutUs8";
import TrustedCompanyAboutUs from "../components/TrustedCompany/TrustedCompanyAboutUs";
import TeamMemberSection from "../components/TeamMember";
import Testimonial from "../components/Testimonial";
import ActionToCall from "../components/ActionToCall";
import Footer from "../components/footer/footer";
import PrivacyPolicyText from "../components/PrivacyPolicy/PrivacyPolicyText";

class PrivacyPolicy extends Component {
  componentDidMount() {
    document.title = "TeqEngine - Privacy Policy";
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="privacy-policy">
        <Header />
        <div className="main">
          <HeroSection title="Privacy Policy" />
          <PrivacyPolicyText />
          <ActionToCall />
        </div>
        <Footer />
      </div>
    );
  }
}

export default PrivacyPolicy;
