import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    const currDate = new Date();
    const currYear = currDate.getFullYear();
    return (
      <div>
        <footer className="footer-section">
          <div className="footer-top pt-5 pb-5">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-4 mb-4 mb-lg-0">
                  <div className="footer-nav-wrap">
                    <img
                      src="/img/teqengine-logo-orange-gray.png"
                      alt="footer logo"
                      width="180"
                      className="img-fluid mb-3"
                    />
                    <p>
                      Expert Consultancy in Full-Stack Software Engineering
                      Solutions, Fractional CTO Services, and Engineering
                      Staffing Solutions. TeqEngine is your definitive solution
                      for transforming software challenges into business
                      advantages.
                    </p>
                    {/* <ul className="list-inline">
                      <li className="list-inline-item">
                        <span className="ti-arrow-circle-right mr-2"></span>{" "}
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li className="list-inline-item">
                        <span className="ti-arrow-circle-right mr-2"></span>{" "}
                        <a href="/terms-and-conditions">Terms and Conditions</a>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap">
                    <h5 className="mb-3">Get in touch</h5>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <span className="ti-arrow-circle-right mr-2"></span>
                        <strong>Email:</strong>
                        <a href="mailto:daniel@teqengine.com">
                          {" "}
                          daniel@teqengine.com
                        </a>
                      </li>
                      <li className="mb-2">
                        <span className="ti-arrow-circle-right mr-2"></span>
                        <strong>Phone:</strong>{" "}
                        <a href="tel:+18585043290">858-504-3290</a>
                      </li>
                      <li className="mb-2">
                        <span className="ti-arrow-circle-right mr-2"></span>
                        <strong>Address:</strong>{" "}
                        <a
                          href="https://www.google.com/maps/place/888+Prospect+St+Suite+200,+La+Jolla,+CA+92037,+USA/@32.8466645,-117.2781358,17.15z/"
                          target="_blank"
                        >
                          888 Prospect Street, Suite 200
                          <br />
                          &nbsp;La Jolla, CA 92037
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="footer-nav-wrap">
                    <h5 className="mb-3">Quick links</h5>
                    <ul className="list-unstyled">
                      {/* <li className="mb-2">
                        <i className="ti-arrow-circle-right mr-2"></i>{" "}
                        <a href="/">Home</a>
                      </li> */}
                      {/* <li className="mb-2">
                        <i className="ti-arrow-circle-right mr-2"></i>{" "}
                        <a href="/services">Services</a>
                      </li> */}
                      {/* <li className="mb-2">
                        <i className="ti-arrow-circle-right mr-2"></i>{" "}
                        <a href="/portfolio">Portfolio</a>
                      </li> */}
                      {/* <li className="mb-2">
                        <i className="ti-arrow-circle-right mr-2"></i>{" "}
                        <a href="/pricing">Pricing</a>
                      </li> */}
                      {/* <li className="mb-2">
                        <i className="ti-arrow-circle-right mr-2"></i>{" "}
                        <a href="/about">About</a>
                      </li> */}
                      <li className="mb-2">
                        <i className="ti-arrow-circle-right mr-2"></i>{" "}
                        <a href="/case-studies">Case Studies</a>
                      </li>
                      <li className="mb-2">
                        <i className="ti-arrow-circle-right mr-2"></i>{" "}
                        <a
                          href="https://teqengine.jobspage.co/0dTeHe69-senior-full-stack-developer-contract"
                          target="_blank"
                        >
                          Careers
                        </a>
                      </li>
                      <li className="mb-2">
                        <i className="ti-arrow-circle-right mr-2"></i>{" "}
                        <a
                          href="https://calendly.com/daniel-teqengine/30min"
                          target="_blank"
                        >
                          Book Discovery Call
                        </a>
                      </li>
                      {/* <li className="mb-2">
                        <i className="ti-arrow-circle-right mr-2"></i>{" "}
                        <a href="/contact">Contact</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom gray-light-bg pt-4 pb-4">
            <div className="container">
              <div className="row text-center text-md-left align-items-center">
                <div className="col-md-6 col-lg-5">
                  <p className="text-md-left copyright-text pb-0 mb-0">
                    Copyrights © {currYear}. All rights reserved by{" "}
                    <a href="/">TeqEngine</a>
                  </p>
                </div>
                <div className="col-md-6 col-lg-7">
                  <ul className="social-list list-inline list-unstyled text-md-right">
                    <li className="list-inline-item">
                      <a
                        href="https://www.linkedin.com/company/97412024"
                        target="_blank"
                        title="LinkedIn"
                      >
                        <span className="ti-linkedin"></span>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <a href="/#" target="_blank" title="Facebook">
                        <span className="ti-facebook"></span>
                      </a>
                    </li> */}
                    {/* <li className="list-inline-item">
                      <a
                        href="https://www.twitter.com/teqengine"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Twitter"
                      >
                        <span className="ti-twitter"></span>
                      </a>
                    </li> */}
                    {/* <li className="list-inline-item">
                      <a
                        href="https://www.instagram.com/teqengine/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Instagram"
                      >
                        <span className="ti-instagram"></span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
