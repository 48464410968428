import React, { Component } from "react";
import _data from "../../data";
import axios from "axios";
import CaseStudyItem from "./CaseStudyItem";

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseStudies: {},
      posts: [],
      assets: [],
    };
    this.API_URL = "https://api.teqengine.com/getPosts";
    this.CASE_STUDY_TYPE = "Case Study";
  }

  componentDidMount() {
    this.setState({
      caseStudies: _data.caseStudies,
    });
    this.getPosts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.posts.length !== this.state.posts.length) {
      // Assuming your carousel is initialized on an element with class 'client-testimonial'
      if (window.jQuery && window.jQuery().owlCarousel) {
        const $carousel = window.jQuery(".client-testimonial");

        // Destroy the existing carousel instance
        $carousel.trigger("destroy.owl.carousel");

        // Reinitialize the carousel
        // Use the same settings as in your external script
        $carousel.owlCarousel({
          loop: true,
          margin: 30,
          nav: false,
          responsiveClass: true,
          autoplay: true,
          autoplayHoverPause: true,
          lazyLoad: true,
          responsive: {
            0: {
              items: 1,
              dot: true,
            },
            600: {
              items: 2,
              dot: true,
            },
            1000: {
              items: 3,
              dot: true,
            },
          },
        });
      }
    }
  }

  async getPosts() {
    try {
      const posts = await axios.get(this.API_URL);
      this.setState({
        posts: posts?.data?.items ?? [],
        assets: posts?.data?.includes?.Asset ?? [],
      });
    } catch (e) {
      throw new Error(e);
    }
  }

  getImageUrlById(imageId) {
    const asset = this.state.assets.find((asset) => asset.sys.id === imageId);
    const imgUrl = asset ? asset?.fields?.file?.url : null;
    const imgTitle = asset ? asset?.fields?.title : null;
    return { imgUrl, imgTitle };
  }

  renderCaseStudies() {
    const caseStudies = (this.state.posts || []).filter(
      (cs) => cs?.fields?.postType === this.CASE_STUDY_TYPE
    );
    return caseStudies.map((caseStudy, index) => {
      const { title, description, body, featuredImage, publishedDate, slug } =
        caseStudy?.fields ?? {};
      const featuredImageId = featuredImage?.sys?.id ?? "";
      const { imgUrl, imgTitle } = this.getImageUrlById(featuredImageId);
      const shortDescription =
        description?.length > 200
          ? `${description.slice(0, 200)}...`
          : description;

      return (
        <CaseStudyItem
          key={index}
          indexKey={index}
          bgColor={this.props.bgColor}
          title={title}
          description={shortDescription}
          imgUrl={imgUrl}
          imgTitle={imgTitle}
          publishedDate={publishedDate}
          slug={slug}
        />
      );
    });
  }

  render() {
    var sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
    };
    return (
      <div>
        <section
          className={
            "testimonial ptb-100 " +
            (this.props.bgColor && this.props.bgColor === "gray"
              ? "gray-light-bg"
              : "")
          }
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>{this.state.caseStudies.title}</h2>
                  <p className="lead">{this.state.caseStudies.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="owl-carousel owl-theme owl-loaded owl-drag client-testimonial case-studies-container dot-indicator">
                {this.renderCaseStudies()}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Testimonial;
