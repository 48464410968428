import React, { Component } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import axios from "axios";

class ContentPostBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featuredImageUrl: "",
      featuredImageTitle: "",
    };
    this.API_URL = "https://api.teqengine.com/getImage";
  }

  componentDidMount() {
    const { post } = this.props;
    const featuredImageId = post?.fields?.featuredImage?.sys?.id;
    if (featuredImageId) {
      this.getImage(featuredImageId);
    }
  }

  componentDidUpdate(prevProps) {
    const { post } = this.props;
    const featuredImageId = post?.fields?.featuredImage?.sys?.id;
    if (featuredImageId !== prevProps.post?.fields?.featuredImage?.sys?.id) {
      this.getImage(featuredImageId);
    }
  }

  async getImage(imageId) {
    try {
      const { data } = await axios.get(`${this.API_URL}/${imageId}`);
      const featuredImageUrl = data?.fields?.file?.url ?? "";
      const featuredImageTitle = data?.fields?.title ?? "";
      this.setState({ featuredImageUrl, featuredImageTitle });
    } catch (e) {
      throw new Error(e);
    }
  }

  convertDate(dateStr) {
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  }

  render() {
    const { post } = this.props;

    if (!post || !post.fields) {
      return null;
    }

    const { title, publishedDate, postType, body } = post.fields;

    return (
      <div className="content-post-body">
        <h1 className="content-post-title">{title}</h1>
        <div className="content-post-meta">
          <div className="content-post-tag">{postType}</div>
          <p className="content-post-date">{this.convertDate(publishedDate)}</p>
        </div>
        <hr className="content-post-divider" />
        {this.state.featuredImageUrl && (
          <div className="content-post-featured-image">
            <img
              src={this.state.featuredImageUrl}
              alt={this.state.featuredImageTitle}
            />
          </div>
        )}
        {documentToReactComponents(body)}
      </div>
    );
  }
}

export default ContentPostBody;
