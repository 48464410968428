import React from "react";
import _data from "../../data";

class ServicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service: {},
      hero: {},
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      service: _data.serviceAboutUs,
      hero: _data.hero,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="imageblock-section switchable gray-light-bg">
          <div className="imageblock-section-img col-lg-6 col-md-4">
            {/* <div
              className="background-image-holder"
              style={{
                background: "url('img/hero-bg-5.jpg')",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "center / cover",
                opacity: 1
              }}
            >
              {" "}
            </div> */}
            <div className="hero-animation-img">
              {(this.state.hero.animations || []).map((image, index) => {
                return (
                  <img
                    className={image.className}
                    src={image.src}
                    alt="animation"
                    width={image.width}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7">
                <div className="about-content ptb-100">
                  <h2>Design. Development. SEO. All-in-one web solutions</h2>
                  <p className="lead">
                    Our services help clients get ROI on their online activities{" "}
                    quickly. At TeqEngine, we optimize the process of getting
                    more leads and conversions for your business.{" "}
                  </p>
                  <div className="mt-4">
                    <ul className="feature-list">
                      <li>
                        Modern, stunning web design to make a great first
                        impression.
                      </li>
                      <li>
                        Experienced software developers to take features from
                        ideation to online.
                      </li>
                      <li>
                        SEO to make Google love your site and rank highly for
                        important keywords.
                      </li>
                      <li>
                        Reliable, fast, and secure hosting that doesn't break
                        the bank.
                      </li>
                      <li>
                        Advanced security to protect your reputation, customers,
                        and data.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ServicePage;
