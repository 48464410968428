import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/heroSectionAboutUs";
import PortfolioLead from "../components/Portfolio/PortfolioLead";
import PortfolioItems from "../components/Portfolio/PortfolioItems";
import Testimonial from "../components/Testimonial";
import ActionToCall from "../components/ActionToCall";
import Footer from "../components/footer/footer";

class Portfolio extends Component {
  componentDidMount() {
    document.title = "TeqEngine - Portfolio";
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="portfolio-page">
        <Header />
        <div className="main">
          <HeroSection title="Portfolio" />
          <PortfolioLead />
          <PortfolioItems />
          <Testimonial />
          <ActionToCall />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Portfolio;
