import React, { Component } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardActionArea,
  CardMedia,
  Typography,
  Button,
} from "@material-ui/core";

export default class PortfolioItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioItems: [],
      width: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.portfolioItems = [
      // {
      //   name: "Noveta Health",
      //   imgDesktop: "portfolio-items/desktop/noveta.png",
      //   imgMobile: "portfolio-items/mobile/noveta-mobile.png",
      //   alt: "Noveta Health - Device Mockup",
      //   description: "",
      //   url: "https://www.novetahealth.com/",
      // },
      {
        name: "Gabriella Alziari",
        imgDesktop: "portfolio-items/desktop/gab-personal.png",
        imgMobile: "portfolio-items/mobile/gab-mobile.png",
        alt: "Gabriella Alziari - Device Mockup",
        description: "",
        url: "https://www.gabriellaalziari.com/",
      },
      {
        name: "Alziari Coaching",
        imgDesktop: "portfolio-items/desktop/alziari-coaching.png",
        imgMobile: "portfolio-items/mobile/alziari-coaching-mobile.png",
        alt: "Alziari Coaching - Device Mockup",
        description: "",
        url: "https://www.alziari.co/",
      },
      // {
      //   name: "Occum Health",
      //   imgDesktop: "portfolio-items/desktop/occum.png",
      //   imgMobile: "portfolio-items/mobile/occum-mobile.png",
      //   alt: "Occum Health - Device Mockup",
      //   description: "",
      //   url: "https://www.occumhealth.com/",
      // },
      // {
      //   name: "Jupiter Digital Solutions",
      //   imgDesktop: "portfolio-items/desktop/jdigital.png",
      //   imgMobile: "portfolio-items/mobile/jdigital-mobile.png",
      //   alt: "Jupiter Digital Solutions - Device Mockup",
      //   description: "",
      //   url: "https://www.jdigitalsolutions.com/",
      // },

      // {
      //   name: "Bright Cleaning Corp",
      //   imgDesktop: "portfolio-items/desktop/bright-cleaning.png",
      //   imgMobile: "portfolio-items/mobile/bright-cleaning-mobile.png",
      //   alt: "Bright Cleaning Corp - Device Mockup",
      //   description: "",
      //   url: "https://www.brightcleaningcorp.com/",
      // },
    ];
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    let width = window.innerWidth;
    this.setState({
      width,
    });
  }

  renderItems() {
    return this.portfolioItems.map((item, i) => {
      const { alt, imgDesktop, imgMobile, name, description, url } = item;
      const _img = this.state.width < 600 ? imgMobile : imgDesktop;
      return (
        <Card className="portfolio-item-card" variant="outlined" key={i}>
          <a
            className="portfolio-item-link"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CardActionArea>
              <CardMedia
                component="img"
                alt={alt}
                height="250"
                image={`img/${_img}`}
                title={alt}
              />
              {/* <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {description}
                </Typography>
              </CardContent> */}
            </CardActionArea>
          </a>
          <CardActions>
            <Typography gutterBottom variant="h5" component="h2">
              {name}
            </Typography>

            {/* <Button size="small" color="primary">
              <a href={url} target="_blank" rel="noopener noreferrer">
                View Site
              </a>
            </Button> */}
          </CardActions>
        </Card>
      );
    });
  }
  render() {
    return (
      <Container className="portfolio-items-container" maxWidth={"lg"}>
        {this.renderItems()}
      </Container>
    );
  }
}
