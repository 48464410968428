import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../components/Header/header";
import Footer from "../components/footer/footer";

export default class NotFound extends React.Component {
  componentDidMount() {
    document.title = "TeqEngine - Not Found";
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="notfound-container">
        <Header />
        <div className="notfound-pg">
          <img
            className="notfound-icon"
            src="/img/page-not-found.svg"
            alt="404 error - page not found"
          />
          <div className="notfound-title">Page Not Found.</div>
          <div className="notfound-subtitle">
            Please navigate through the{" "}
            <NavLink to="/" className="notfound-link">
              Home
            </NavLink>{" "}
            page.
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
