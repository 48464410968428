import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/heroSectionAboutUs";
import ServiceSection from "../components/Service/ServiceAboutUs";
import MissionSection from "../components/Business";
import BusinessSection from "../components/AboutUs";
import AboutSection from "../components/AboutUs/AboutUs8";
import TrustedCompanyAboutUs from "../components/TrustedCompany/TrustedCompanyAboutUs";
import TeamMemberSection from "../components/TeamMember";
import Testimonial from "../components/Testimonial";
import ActionToCall from "../components/ActionToCall";
import Footer from "../components/footer/footer";
import TermsAndConditionsText from "../components/TermsAndConditions/TermsAndConditionsText";

class TermsAndConditions extends Component {
  componentDidMount() {
    document.title = "TeqEngine - Terms & Conditions";
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="terms-and-conditions">
        <Header />
        <div className="main">
          <HeroSection title="Terms and Conditions" />
          <TermsAndConditionsText />
          <ActionToCall />
        </div>
        <Footer />
      </div>
    );
  }
}

export default TermsAndConditions;
