import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div>
      {/* start header section */}
      <section
        className="call-to-action ptb-100 background-img"
        style={{
          background: "url('/img/laptop-code.jpeg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11 mb-3">
              <div className="call-to-action-content text-center text-white">
                <h2 className="text-white">
                  Engineering Your Vision into Reality
                </h2>
                <p>
                  Full-Stack Development, App Development, Cloud-Native
                  Solutions, Fractional CTO Services, and Premier Engineering
                  Staffing Solutions
                </p>
              </div>
            </div>
            <div className="col-11">
              <div className="call-to-action-btn text-center">
                <a
                  href="https://calendly.com/daniel-teqengine/30min"
                  target="_blank"
                  className="btn solid-btn"
                >
                  {" "}
                  Book a Discovery Call
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end header section */}
    </div>
  );
};

export default About;
