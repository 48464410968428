import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/heroSection5";
import PromoSection from "../components/PromoSection/Promo5";
import AboutSection from "../components/AboutUs/AboutUs2";
import CounterSection from "../components/CounterSection/counter5";
import ServiceSection from "../components/Service/Service5";
import Pricing from "../components/Pricing";
import Testimonial from "../components/Testimonial";
import Contact from "../components/Contact";
import ActionToCall from "../components/ActionToCall";
import Footer from "../components/footer/footer";

class Theme5 extends Component {
  componentDidMount() {
    document.title = "TeqEngine - Home";
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="home-page">
        <Header />
        <div className="main">
          <HeroSection />
          <PromoSection />
          <ActionToCall />
          <AboutSection />
          {/* <CounterSection /> */}
          <Testimonial bgColor="white" />
          <ServiceSection />
          <Pricing bgColor="white" />
          <Contact bgColor="gray" />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Theme5;
