import React, { Component } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import getParams from "../../helpers/get-query-params";
import BlogPost from "./BlogPost";

export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      assets: [],
      postTypeSelected: "all",
      loading: "true",
    };
    this.API_URL = "https://api.teqengine.com/getPosts";
    this.categories = [
      "all",
      "local-business",
      "seo",
      "digital-marketing",
      "cyber-security",
      "mobile-dev",
      "web-dev",
      "wordpress",
      "serverless",
      "cloud",
      "devops",
    ];
  }

  componentDidMount() {
    this.checkParams();
    this.getPosts();
  }

  setPostType(type) {
    this.setState({
      postTypeSelected: type,
    });
  }

  checkParams() {
    const { location, history } = this.props.parent;
    if (location.search) {
      const params = getParams(location.search);
      const { type } = params;
      const isValidCategory = this.categories.indexOf(type) !== -1;
      if (isValidCategory) {
        return;
      } else {
        history.push("/case-studies");
        return;
      }
    } else {
      history.push("/case-studies");
      return;
    }
  }

  async getPosts() {
    this.setState({ isLoading: true }); // Start loading
    try {
      const { location } = this.props.parent;
      const params = getParams(location.search);
      const posts = await axios.get(this.API_URL, { params });
      this.setState({
        posts: posts?.data?.items ?? [],
        assets: posts?.data?.includes?.Asset ?? [],
        isLoading: false, // Finished loading
      });
    } catch (e) {
      this.setState({ isLoading: false }); // Finished loading, handle error appropriately
      throw new Error(e);
    }
  }

  renderPosts(posts, assets) {
    return posts.map((post) => (
      <BlogPost key={post.sys.id} post={post} assets={assets} />
    ));
  }

  renderArticleSelector() {
    return (
      <div className="articles-selector">
        <div
          className={`btn ${
            this.state.postTypeSelected === "all" ? "btn-selected" : ""
          }`}
          onClick={() => this.setPostType("all")}
        >
          <span>All</span>
        </div>
        <div
          className={`btn ${
            this.state.postTypeSelected === "blog" ? "btn-selected" : ""
          }`}
          onClick={() => this.setPostType("blog")}
        >
          <i className="far fa-newspaper"></i>
          &nbsp;
          <span>Blog</span>
        </div>
        <div
          className={`btn ${
            this.state.postTypeSelected === "case_study" ? "btn-selected" : ""
          }`}
          onClick={() => this.setPostType("case_study")}
        >
          <i className="far fa-file-code"></i>
          &nbsp;
          <span>Case Study</span>
        </div>
      </div>
    );
  }

  render() {
    const { isLoading, posts, assets } = this.state;
    return (
      <div className="case-studies-wrapper">
        <div className="case-studies-top">
          <h1 className="case-studies-title">Our Case Studies</h1>
        </div>
        <div className="case-studies-body">
          {isLoading ? (
            <div className="case-studies-loader"></div>
          ) : (
            this.renderPosts(posts, assets)
          )}
        </div>
      </div>
    );
  }
}
