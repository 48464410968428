import React from "react";
import { Link } from "react-router-dom";

const getImageUrlById = (assets, imageId) => {
  const asset = assets.find((asset) => asset.sys.id === imageId);
  const imgUrl = asset ? asset?.fields?.file?.url : null;
  const imgTitle = asset ? asset?.fields?.title : null;
  return { imgUrl, imgTitle };
};

const convertDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options);
};

const BlogPost = ({ post, assets }) => {
  const { title, description, featuredImage, publishedDate, slug } =
    post?.fields ?? {};

  const featuredImageId = featuredImage?.sys?.id ?? "";
  const { imgUrl, imgTitle } = getImageUrlById(assets, featuredImageId);
  const shortDescription =
    description?.length > 300 ? `${description.slice(0, 300)}...` : description;

  return (
    <Link to={`/case-studies/${slug}`} className="case-studies-blog-post-card">
      {imgUrl && (
        <img
          src={imgUrl}
          alt={imgTitle || "Featured Image"}
          className="case-studies-featured-image"
        />
      )}
      <div className="case-studies-post-content">
        <h2 className="case-studies-post-title">{title}</h2>
        <p className="case-studies-post-date">{convertDate(publishedDate)}</p>
        <p className="case-studies-post-description">{shortDescription}</p>
      </div>
    </Link>
  );
};

export default BlogPost;
