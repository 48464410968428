import React from "react";
import _data from "../../data";

class Promo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {},
      trustedCompany: [],
    };
  }

  componentDidMount() {
    this.setState({
      promo: _data.promo,
      trustedCompany: _data.trustedCompany,
    });
  }

  render() {
    const itemImgBg = (item) =>
      item.title === "Fractional CTO Services"
        ? `url(${item.image})`
        : `linear-gradient(rgba(32, 97, 195, 0.15), rgba(32, 97, 195, 0.15)), url(${item.image})`;
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
          <div className="container">
            <div className="row justify-content-between pb-100">
              <div className="col-md-12">
                <div className="client-section-wrap d-flex flex-row align-items-center">
                  <p className="lead mr-5 mb-0">Technology Partners:</p>
                  <ul className="list-inline justify-content-between home-tech-partners">
                    {(this.state.trustedCompany || []).map(
                      (techPartner, index) => {
                        return (
                          <li className="list-inline-item" key={index}>
                            <img
                              src={techPartner.src}
                              style={techPartner.style}
                              alt={techPartner.alt}
                              className="img-fluid"
                            />
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>{this.state.promo.title}</h2>
                  <p className="lead">{this.state.promo.description}</p>
                </div>
              </div>
            </div>
            <div className="row equal">
              {(this.state.promo.items || []).map((item) => {
                return (
                  <div className="col-md-4 col-lg-4" key={item.title}>
                    <div className="single-promo single-promo-hover text-center gray-light-bg h-100">
                      <div>
                        <div
                          style={{
                            background: itemImgBg(item),
                          }}
                          alt={`${item.title} Consutling Services`}
                          className="img-fluid img-full-box promo-img"
                        />
                      </div>
                      <div className="p-4">
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Promo;
