import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Header extends React.Component {
  getActiveLinkStyles(route) {
    const isCurrentRoute = route === window.location.pathname;
    return {
      fontWeight: isCurrentRoute ? "bold" : "normal",
    };
  }

  render() {
    return (
      <React.Fragment>
        <header className="header">
          <nav className="navbar navbar-expand-lg fixed-top bg-transparent">
            <div className="container">
              <a className="navbar-brand" href="/">
                <img
                  src="/img/teqengine-logo-orange-white.png"
                  width="180"
                  alt="teqengine logo"
                  className="img-fluid"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu"></span>
              </button>

              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  {/* <li className="nav-item">
                    <a
                      className="nav-link page-scroll"
                      href="/"
                      style={this.getActiveLinkStyles("/")}
                    >
                      Home
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      className="nav-link page-scroll"
                      href="/services"
                      style={this.getActiveLinkStyles("/services")}
                    >
                      Services
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      className="nav-link page-scroll"
                      href="/portfolio"
                      style={this.getActiveLinkStyles("/portfolio")}
                    >
                      Portfolio
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      className="nav-link page-scroll"
                      href="/pricing"
                      style={this.getActiveLinkStyles("/pricing")}
                    >
                      Pricing
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      className="nav-link page-scroll"
                      href="/about"
                      style={this.getActiveLinkStyles("/about")}
                    >
                      About
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link page-scroll case-studies-nav"
                      href="/case-studies"
                      style={this.getActiveLinkStyles("/case-studies")}
                    >
                      Case Studies
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link page-scroll"
                      href="https://teqengine.jobspage.co/0dTeHe69-senior-full-stack-developer-contract"
                      target="_blank"
                    >
                      Careers
                    </a>
                  </li> */}

                  {/* <li className="nav-item">
                    <a
                      className="nav-link page-scroll"
                      href="/contact"
                      style={this.getActiveLinkStyles("/contact")}
                    >
                      Contact
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({}))(Header);
