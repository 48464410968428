import { GET_RECAPTCHA } from "../constants/types.js";

const recaptcha = (state = {}, action) => {
  switch (action.type) {
    case GET_RECAPTCHA:
      const { isVerified } = action;
      return {
        ...state,
        isVerified,
      };
    default:
      return state;
  }
};

export default recaptcha;
