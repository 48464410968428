import { combineReducers } from "redux";
import contact from "./contact";
import promo from "./promo";
import quote from "./quote";
import recaptcha from "./recaptcha";

export default combineReducers({
  contact,
  promo,
  quote,
  recaptcha,
});
