import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/heroSectionAboutUs";
import BlogBody from "../components/Blog";
import Footer from "../components/footer/footer";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "TeqEngine - Case Studies";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header />
        <div className="main">
          <HeroSection title="Case Studies" />
          <BlogBody parent={this.props} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Blog;
