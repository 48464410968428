import {
  SUBMIT_CONTACT,
  POST_PROMO,
  GET_QUOTE,
  GET_RECAPTCHA,
} from "../constants/types.js";

export const submitContact = (contactData) => {
  return {
    type: SUBMIT_CONTACT,
    contactData,
  };
};

export const postPromo = (promoData) => {
  return {
    type: POST_PROMO,
    promoData,
  };
};

export const getQuote = (quoteData) => {
  return {
    type: GET_QUOTE,
    quoteData,
  };
};

export const requestRecaptcha = (isVerified) => {
  return {
    type: GET_RECAPTCHA,
    isVerified,
  };
};
