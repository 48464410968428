import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

class CaseStudyItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: "",
      imgTitle: "",
    };
    this.API_URL = "https://api.teqengine.com/getImage";
  }

  async componentDidMount() {}

  async getImage(imageId) {
    try {
      const { data } = await axios.get(`${this.API_URL}/${imageId}`);
      const imgSrc = data?.fields?.file?.url ?? "";
      const imgTitle = data?.fields?.title ?? "";
      this.setState({ imgSrc, imgTitle });
    } catch (e) {
      throw new Error(e);
    }
  }

  convertDate(dateStr) {
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  }

  render() {
    return (
      <Link
        to={`/case-studies/${this.props.slug}`}
        className="case-study-item-container"
      >
        <div className="case-study-item item" key={this.props.indexKey}>
          <div className={this.props.bgColor === "gray" ? "white-bg" : ""}>
            <div className="media-body">
              <img
                className="case-study-item-img"
                src={this.props.imgUrl}
                alt={this.props.imgTitle}
              />
            </div>
            <h5 className="mb-0 case-study-item-title">{this.props.title}</h5>
            <div className="mb-4 mt-4 lead case-study-item-desc">
              {this.props.description}
            </div>
            <div className="case-study-published-date">
              {this.convertDate(this.props.publishedDate)}
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default CaseStudyItem;
