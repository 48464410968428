import React, { Component } from "react";
import axios from "axios";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/heroSectionAboutUs";
import Footer from "../components/footer/footer";
import ContentPostBody from "../components/ContentPost";
import ActionToCall from "../components/ActionToCall";
import PageHead from "../components/PageHead";

class ContentPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {},
      isLoading: true,
      error: null,
    };
    this.API_URL = "https://api.teqengine.com";
  }

  componentDidMount() {
    document.title = "TeqEngine - Case Study";
    window.scrollTo(0, 0);
    this.fetchPost();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.post !== this.state.post && this.state.post.fields) {
      document.title = `TeqEngine - Case Study - ${this.state.post.fields.title}`;
    }
  }

  fetchPost = async () => {
    const slug = this.props.match.params.slug; // Assuming you're using react-router-dom
    const contentfulApiUrl = `${this.API_URL}/getPost/${slug}`; // Update with your actual API endpoint

    try {
      const response = await axios.get(contentfulApiUrl);
      this.setState({ post: response.data, isLoading: false });
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
    }
  };

  renderPostBody = (post) => {
    if (!post || !post?.fields) return null;
    const body = post?.field?.body ?? "";
    return documentToReactComponents(body);
  };

  render() {
    const { post, isLoading, error } = this.state;
    const title = post.fields
      ? `TeqEngine - Case Study - ${post.fields.title}`
      : "TeqEngine - Case Study";
    const description = post.fields ? post.fields.description : "";
    const imageUrl = "/img/teqengine-logo-orange-gray.png";

    return (
      <div className="content-post-main">
        <PageHead title={title} description={description} imageUrl={imageUrl} />
        <Header />
        <div className="main">
          <HeroSection
            title={post.fields ? post.fields.title : "Case Studies"}
          />
          {isLoading && <div className="loader"></div>}
          {error && <div>Error: {error}</div>}
          {!isLoading && !error && <ContentPostBody post={post} />}
          <ActionToCall />
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContentPost;
